define('ember-config-service/services/config', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      computed = _ember.default.computed,
      get = _ember.default.get,
      getOwner = _ember.default.getOwner;
  exports.default = Service.extend({
    __config__: computed(function () {
      return getOwner(this).factoryFor('config:environment').class;
    }),

    unknownProperty: function unknownProperty(path) {
      return get(this, '__config__.' + path);
    }
  });
});